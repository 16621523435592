import Alert from "../../../helpers/AlertHelper";
import SecurityService from "../oidc";
import subdomain from "@/helpers/subdomain";
import Event from "@/helpers/event";

const SingletonInstanceSymbol = Symbol();
const InstanceSymbol = Symbol();
const signalR = require('@microsoft/signalr');

class SignalR {
  constructor(instance) {
    if (instance !== SingletonInstanceSymbol) {
      throw new Error('Cannot Construct SignalR Instance');
    }

    this.connections = [];
  }

  static get instance() {
    if (!this[InstanceSymbol]) {
      this[InstanceSymbol] = new SignalR(SingletonInstanceSymbol);
    }
    return this[InstanceSymbol];
  }

  getLogLevel() {
    return `${process.env.VUE_APP_VERSION}` === "development"
      ? signalR.LogLevel.Information
      : signalR.LogLevel.Error;
  }

  async setConnection(url) {
    const user = await SecurityService.Mgr().getUser();
    const log = this.getLogLevel();

    const connection = new signalR.HubConnectionBuilder()
      .withUrl(url, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => user.access_token
      })
      .configureLogging(log)
      .withAutomaticReconnect()
      .build();

    this.connections.push(connection);

    return connection;
  }

  async start() {
    for (const connection of this.connections) {
      try {
        await connection.start();

        connection.on("MessageUser", (message) => {
          Alert.notify('error', message, '', '', false, 1000000000000000);
        });

        connection.on('closeinvoice', (message) => {
          Event.emit('signalRCloseInvoice', message);
        });

        connection.on('ImportNotification', (message) => {
          Event.emit('signalRImportNotification', message);
        });

        connection.on('ExportNotification', (message) => {
          Event.emit('signalRExportNotification', message);
        });
      } catch (err) {
        console.error('Failed to start connection, retrying...', err);
        setTimeout(() => this.start(), 5000);
      }
    }
  }

  async initConnections() {
    await this.setConnection(`${process.env.VUE_APP_PROTOCOL}${subdomain}${process.env.VUE_APP_PROVIDER_GATEWAY}/signalr-hubs/message-user`);
    const protocolSignIR = process.env.VUE_APP_PROTOCOL === 'https://' ? 'wss' : 'ws';

    await this.setConnection(`${protocolSignIR}:${subdomain}${process.env.VUE_APP_PROVIDER_GATEWAY}/bulk-invoice-generation/hub`);
    await this.setConnection(`${protocolSignIR}:${subdomain}${process.env.VUE_APP_PROVIDER_GATEWAY}/signalr-hubs/import`);
    await this.setConnection(`${protocolSignIR}:${subdomain}${process.env.VUE_APP_PROVIDER_GATEWAY}/signalr-hubs/export`);

    await this.start();
  }
}

export default SignalR.instance;
